import React, { useEffect, useState, useRef } from 'react';
import { Button, Col } from 'react-bootstrap';
import { GoogleMap, Marker, Polyline, useJsApiLoader, InfoWindow, InfoWindowF } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { useDispatch } from 'react-redux';
import { getTripHistoryApi } from '../../../redux/actions/VehicleDashboardActions/getTripHistoryAction';
import { useSelector } from 'react-redux';
import Map_Pointer_Green from '../../../utils/images/Map_Pointer_Green.png'
import Map_Pointer_Red from '../../../utils/images/Map_Pointer_Red.png'
import Green_Bus from '../../../utils/images/Green_Bus.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TopbarProfile from '../../Layout/components/topbar/TopbarProfile';
import MapSelectOption from './MapSelectOption';
import { renderToString } from 'react-dom/server';
import DotDotLoader from '@/shared/components/DotDotLoader';

const containerStyle = {
  height: '700px',
};



const greenLineOptions = {
  strokeColor: '#212529',
  strokeOpacity: 2,
  strokeWeight: 4,
};







const VehicleMap = ({ allBusData, loading, imeiData }) => {
  const { t } = useTranslation('common');
  const [selectedVic, setSelectedVic] = useState('')
  const [center, setCenter] = useState(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
  });
  const dispatch = useDispatch()
  const tripHistoryData = useSelector(state => state?.busTripData?.tripHistoryData?.location_details)
  const [newHistoryData, setNewHistoryData] = useState([])
  const [busPosition, setBusPosition] = useState({ lat: 0, lng: 0 });
  const [isDisable, setIsDisable] = useState(false)
  const [currIndex, setCurrIndex] = useState(0)
  const [busRotation, setBusRotation] = useState(0);
  const history = useHistory()
  const [isHoveredOnIcon, setisHoveredOnIcon] = useState(false);
  const vehicleRef = useRef(null);
  const [uniId, setUniId] = useState(null)
  const [filterArr, setFilterArr] = useState([])



  useEffect(() => {
    const allVic = allBusData
    const RunningData = allBusData.filter((elem) => elem.mqttSpeed > 0)
    const stopDataData = allBusData.filter((elem) => elem.mqttSpeed == 0)
    const offlineData = allBusData.filter((elem) => elem.mqttSpeed == null)

    if (selectedVic == 'Running') {
      setFilterArr(RunningData)

    }

    else if (selectedVic == 'All Vehicle') {
      setFilterArr(allVic)
    }
    else if (selectedVic == 'Stationary') {
      setFilterArr(stopDataData)
    }
    else if (selectedVic == 'Offline') {
      setFilterArr(offlineData)

    }
    else {
      setFilterArr(allBusData)
    }
  }, [selectedVic, allBusData])


  const handleMouseOver = (id) => {
    setUniId(id)
    setisHoveredOnIcon(true);
    if (vehicleRef.current) {
      window.google.maps.event.trigger(vehicleRef.current, "click");
    }
  };

  useEffect(() => {
    setNewHistoryData(tripHistoryData?.map((elem, i) => {
      return {
        lat: elem.lat,
        lng: elem.lon
      }
    }))

    if (Array.isArray(tripHistoryData) && tripHistoryData.length > 0) {
      setCenter({
        lat: tripHistoryData[tripHistoryData.length - 1].lat, lng: tripHistoryData[tripHistoryData.length - 1].lon
      })
    }
    else {
      setCenter(
        { lat: 28.409369, lng: 77.116256 }
      )
    }
  }, [tripHistoryData])






  useEffect(() => {
    if (isDisable) {
      let currentIndex = currIndex;

      const interval = setInterval(() => {
        setCurrIndex(currentIndex + 2)
        setBusPosition(newHistoryData[currentIndex]);
        currentIndex = (currentIndex + 2) % newHistoryData.length;

        if (busPosition && newHistoryData) {

          const dLng = newHistoryData[currIndex - 1]?.lng - busPosition.lng;
          const dLat = newHistoryData[currIndex - 1]?.lat - busPosition.lat;
          return setBusRotation((Math.atan2(dLng, dLat) * 180) / Math.PI);
        }


      }, 200);
      return () => clearInterval(interval)
    }


  }, [busPosition])



  // const viewBusDetailHndler = () => {
  //   const id = 10042
  //   getTripHistoryApi(id)
  //   history.push('/vehicleView')
  // }





  const getPath = (icon_component) => {
    const BusIcon = icon_component;
    const svgString = renderToString(BusIcon);
    const svgElement = document.createElement("div");
    svgElement.innerHTML = svgString;

    const svgElements = svgElement.querySelectorAll("path");
    let svgPath = "M0 0";
    svgElements.forEach((obj) => {
      svgPath += ` ${obj.getAttribute("d")}z`;
    });

    return svgPath;
  };

  const NewSvgBusIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="64"
      viewBox="0 0 25 64"
      fill="none">
      <g clipPath="url(#clip0_440_1194)">
        <path
          d="M22.841 61.75H1.94922V62.5694H22.841V61.75Z"
          fill="url(#paint0_linear_440_1194)"
        />
        {/* <path
          d="M21.4813 62.0579H3.46164C2.76976 62.0579 2.20703 61.5005 2.20703 60.8151V16.5616C2.20703 15.8763 2.76976 15.3188 3.46164 15.3188H21.4782C22.1701 15.3188 22.7329 15.8763 22.7329 16.5616V60.8151C22.7359 61.5005 22.1732 62.0579 21.4813 62.0579Z"
          fill="#EFB700"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        /> */}
        <path
          d="M2.20703 14.9198V3.09189C2.20703 2.72636 2.33311 2.36997 2.57296 2.09582C2.85586 1.76989 3.35402 1.41654 4.22118 1.24292C6.02007 0.88348 13.3725 0.109776 20.442 1.20027C20.8048 1.2551 21.1615 1.35867 21.4967 1.51402C22.0256 1.75771 22.6375 2.19025 22.7206 2.89389C22.7605 3.22896 22.7175 14.9229 22.7175 14.9229H2.20703V14.9198Z"
          fill="#EFB700"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M3.3449 13.9935C3.3449 13.9935 2.88672 8.96748 4.04908 5.49495C4.54723 4.00846 5.69115 2.81744 7.16408 2.24173C9.26433 1.42234 13.4494 0.414082 18.1173 2.47323C19.3166 3.00021 20.2699 3.95972 20.768 5.16292C21.4322 6.76821 22.0134 9.50359 21.626 13.9966C21.626 13.9935 13.3111 11.7668 3.3449 13.9935Z"
          fill="#EFB700"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M2.61328 4.82828C2.61328 4.82828 3.28979 1.50805 5.61451 1.37402L5.27626 2.66861C5.27626 2.66861 4.12312 2.9062 3.38819 4.44143L2.61328 4.82828Z"
          fill="white"
          stroke="#312D2E"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M22.3879 4.82828C22.3879 4.82828 21.7114 1.50805 19.3867 1.37402L19.725 2.66861C19.725 2.66861 20.8781 2.9062 21.613 4.44143L22.3879 4.82828Z"
          fill="white"
          stroke="#312D2E"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M4.91992 13.2685L7.01402 4.20948C7.14624 3.63987 7.45375 3.12203 7.90885 2.74736C8.64993 2.13815 10.0368 1.42841 12.5029 1.37358C15.0614 1.31875 16.4513 2.18079 17.1647 2.91185C17.5583 3.31394 17.8227 3.81958 17.9519 4.36179L20.0675 13.2685C20.0644 13.2685 12.0847 11.715 4.91992 13.2685Z"
          stroke="#312D2E"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M7.41406 2.14454V1.9435C7.41406 1.9435 9.63423 0.594087 12.2542 0.636732C14.8741 0.679377 16.347 1.25204 17.574 1.91609V2.24202C17.574 2.24506 13.3273 0.0823453 7.41406 2.14454Z"
          fill="black"
        />
        <path
          d="M4.22246 1.2398C4.22246 1.2398 3.88728 0.228503 12.4728 4.71569e-05C12.4728 4.71569e-05 19.9912 -0.0273674 20.6677 1.2398C20.6677 1.2398 11.6333 -0.152257 4.22246 1.2398Z"
          fill="black"
        />
        <path
          d="M0.153612 17.1648C0.153612 17.1648 0.113636 16.6866 0.48264 16.187C0.851643 15.6874 2.20773 14.999 2.20773 14.999H2.77046V16.0834L0.153612 17.1648Z"
          fill="#EFB700"
        />
        <path
          d="M0.153612 17.1648C0.153612 17.1648 0.113636 16.6866 0.48264 16.187C0.851643 15.6874 2.20773 14.999 2.20773 14.999H2.77046V16.0834L0.153612 17.1648Z"
          stroke="black"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M24.8462 17.1648C24.8462 17.1648 24.8862 16.6866 24.5172 16.187C24.1482 15.6874 22.7921 14.999 22.7921 14.999H22.2324V16.0834L24.8462 17.1648Z"
          fill="#EFB700"
        />
        <path
          d="M24.8462 17.1648C24.8462 17.1648 24.8862 16.6866 24.5172 16.187C24.1482 15.6874 22.7921 14.999 22.7921 14.999H22.2324V16.0834L24.8462 17.1648Z"
          stroke="black"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M2.82991 14.9017C2.82991 14.9017 11.9689 11.3134 22.3902 14.9961V15.6875L19.3889 17.2989C19.3889 17.2989 11.3723 15.7454 5.61896 17.2989L2.77148 15.7637L2.82991 14.9017Z"
          fill="black"
        />
        <path
          d="M4.10629 24.4932H2.49805V28.9932H4.10629V24.4932Z"
          fill="black"
        />
        <path
          d="M22.4793 24.4932H20.8711V28.9932H22.4793V24.4932Z"
          fill="black"
        />
        <path
          d="M4.09066 30.3633H2.48242V34.8633H4.09066V30.3633Z"
          fill="black"
        />
        <path
          d="M22.4637 30.3633H20.8555V34.8633H22.4637V30.3633Z"
          fill="black"
        />
        <path
          d="M4.09066 36.2329H2.48242V40.7329H4.09066V36.2329Z"
          fill="black"
        />
        <path
          d="M22.4637 36.2329H20.8555V40.7329H22.4637V36.2329Z"
          fill="black"
        />
        <path d="M4.09066 42.106H2.48242V46.606H4.09066V42.106Z" fill="black" />
        <path d="M22.4637 42.106H20.8555V46.606H22.4637V42.106Z" fill="black" />
        <path
          d="M4.09066 47.9756H2.48242V52.4756H4.09066V47.9756Z"
          fill="black"
        />
        <path
          d="M22.4637 47.9756H20.8555V52.4756H22.4637V47.9756Z"
          fill="black"
        />
        <path
          d="M4.09066 53.8452H2.48242V58.3452H4.09066V53.8452Z"
          fill="black"
        />
        <path
          d="M22.4637 53.8452H20.8555V58.3452H22.4637V53.8452Z"
          fill="black"
        />
        <path
          d="M22.4793 23.7836V16.0801L20.8711 17.0122V23.7836H22.4793Z"
          fill="black"
        />
        <path
          d="M2.49805 23.7836V16.0801L4.10629 17.0122V23.7836H2.49805Z"
          fill="black"
        />
        <path
          d="M4.43945 18.0391V60.6325C4.43945 60.7939 4.50403 60.9401 4.61165 61.062C4.78386 61.263 5.04216 61.3666 5.30969 61.3666H19.6178C19.8853 61.3666 20.1467 61.263 20.3189 61.062C20.4265 60.9371 20.4911 60.7909 20.4911 60.6294V18.0544C20.4911 17.9356 20.4173 17.8259 20.3035 17.7833C20.2205 17.7528 20.1283 17.7589 20.0545 17.8015C19.8423 17.9264 19.3441 18.1457 18.7414 17.9021C17.9419 17.5761 10.5772 16.446 6.17377 17.9081C6.13687 17.9203 6.10304 17.9356 6.06922 17.9477C6.01079 17.9691 5.88164 18.0087 5.66639 18.0026C5.46959 17.9995 5.27894 17.9538 5.09751 17.8777L4.87611 17.7863C4.77156 17.7437 4.68853 17.7437 4.61473 17.7772C4.5071 17.8259 4.43945 17.9264 4.43945 18.0391Z"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M15.7592 26.5403H9.36626C7.77032 26.5403 6.47266 25.2579 6.47266 23.674C6.47266 22.0931 7.76724 20.8076 9.36626 20.8076H15.7592C17.3552 20.8076 18.6529 22.09 18.6529 23.674C18.6498 25.2579 17.3552 26.5403 15.7592 26.5403Z"
          fill="#2BA23C"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M15.7597 41.3504H9.39751C7.80157 41.3504 6.50391 40.068 6.50391 38.484C6.50391 36.9031 7.79849 35.6177 9.39751 35.6177H15.7567C17.3526 35.6177 18.6503 36.9001 18.6503 38.484C18.6503 40.068 17.3557 41.3504 15.7597 41.3504Z"
          fill="#2BA23C"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M15.7596 56.1639H9.1729C7.57696 56.1639 6.2793 54.8815 6.2793 53.2975C6.2793 51.7166 7.57388 50.4312 9.1729 50.4312H15.7565C17.3525 50.4312 18.6501 51.7136 18.6501 53.2975C18.6501 54.8815 17.3556 56.1639 15.7596 56.1639Z"
          fill="#2BA23C"
          stroke="black"
          strokeWidth="0.25"
          strokeMiterlimit="10"
        />
        <path
          d="M22.7363 5.7876C22.7363 5.7876 23.1884 5.79674 23.7234 6.11353C24.2308 6.41814 24.4952 6.82022 24.4952 7.23144V14.5542C24.4952 14.5542 24.4952 14.8284 24.0401 15.3157L22.8409 14.9959L22.7363 5.7876Z"
          fill="black"
        />
        <path
          d="M2.20814 5.7876C2.20814 5.7876 1.75611 5.79674 1.22105 6.11353C0.713671 6.41814 0.449219 6.82022 0.449219 7.23144V14.5542C0.449219 14.5542 0.449219 14.8284 0.904323 15.3157L2.10359 14.9959L2.20814 5.7876Z"
          fill="black"
        />
        <path
          d="M22.7363 45.8071C22.7363 45.8071 23.9971 46.0752 24.1724 46.9951C24.3476 47.915 24.1724 54.5738 24.1724 54.5738C24.1724 54.5738 24.2 55.6094 22.7363 55.8409V45.8071Z"
          fill="black"
        />
        <path
          d="M2.2073 45.8071C2.2073 45.8071 0.946537 46.0752 0.77126 46.9951C0.595983 47.915 0.77126 54.5738 0.77126 54.5738C0.77126 54.5738 0.743585 55.6094 2.2073 55.8409V45.8071Z"
          fill="black"
        />
        <path
          d="M6.15874 62.5693H3.52344C3.52344 62.8983 3.79097 63.1633 4.12307 63.1633H5.55603C5.88814 63.1633 6.15874 62.8983 6.15874 62.5693Z"
          fill="#E53A13"
        />
        <path
          d="M21.2857 62.5879H18.6504C18.6504 62.9169 18.9179 63.1819 19.25 63.1819H20.683C21.0151 63.1819 21.2857 62.9169 21.2857 62.5879Z"
          fill="#E53A13"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_440_1194"
          x1="12.3948"
          y1="61.9541"
          x2="12.3948"
          y2="62.7986"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.0474" stopColor="#EEEEEE" />
          <stop offset="0.2541" stopColor="#A7A7A7" />
          <stop offset="0.4491" stopColor="#6B6B6B" />
          <stop offset="0.6266" stopColor="#3D3D3D" />
          <stop offset="0.7832" stopColor="#1C1C1C" />
          <stop offset="0.913" stopColor="#070707" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_440_1194">
          <rect width="25" height="63.1818" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );





  return (
    <>
      <Col xs={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <CardTitleWrap>
              <CardTitle>Vechicle Map
              </CardTitle>

              <DotDotLoader loadingState={loading} />

              <span><MapSelectOption setSelectedVic={setSelectedVic} /></span>

            </CardTitleWrap>
            {isLoaded ? (
              <GoogleMap
                id="basicMap"
                mapContainerStyle={containerStyle}
                center={allBusData && Array.isArray(allBusData) && allBusData.length ? { lat: allBusData[0].lat, lng: allBusData[0].lng } : { lat: 28.409369, lng: 77.116256 }}
                zoom={12}
                height={400}
              >
                {imeiData && filterArr && filterArr.map((elem, i) => {
                  const busNum = imeiData.find((obj) =>{ 
                    return obj.imei == elem.imei})
                  const angle = Math.atan2(
                    elem.lng - elem.mqttLng,
                    elem.lat - elem.mqttLat
                  );
                  const vicRotation = (angle * 180) / Math.PI ? (angle * 180) / Math.PI + 180 : 0;

                  return   <Marker
                      key={i}
                      // onClick={viewBusDetailHndler}
                      onMouseOver={() => handleMouseOver(i)}
                      onMouseOut={() => setisHoveredOnIcon(false)}
                      ref={vehicleRef}
                      position={elem.mqttLat ? { lat: elem.mqttLat, lng: elem.mqttLng } : { lat: elem.lat, lng: elem.lng }}
                      icon={{
                        anchor: new google.maps.Point(12, 30),
                        path: getPath(<NewSvgBusIcon />),
                        scale: 0.7,
                        rotation: vicRotation,
                        fillColor: "yellow",
                        fillOpacity: 1,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "#000000",
                      }}
                    >
                      {isHoveredOnIcon && uniId == i && (
                        <InfoWindowF
                          className="p-0 m-0"
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -10),
                            maxWidth: 200,
                            closeBoxURL: "",
                          }}>
                          <div
                            className="p-2 d-flex flex-column justify-content-center"
                          >
                            {/* 
                            <p className="m-0">
                              Bus No. : <b>HR 55 RR 2255</b>
                            </p> */}
                            <p className="m-0 mb-1">
                              Speed : <b>{elem.mqttSpeed ? `${elem.mqttSpeed} kph` : `0 kph`} </b>
                            </p>
                            <p className="m-0 mb-1">
                              Vehicle No : <b>{busNum?.veh_num && busNum.veh_num} </b>
                            </p>
                            
                          </div>
                        </InfoWindowF>
                      )}
                    </Marker>
                  


                })}

                {!filterArr && <>
                  <Marker
                    onClick={viewBusDetailHndler}
                    onMouseOver={() => handleMouseOver(1)}
                    onMouseOut={() => setisHoveredOnIcon(false)}
                    ref={vehicleRef}
                    position={{ lat: 28.409369, lng: 77.116256 }}
                    icon={{
                      anchor: new google.maps.Point(12, 30),
                      path: getPath(<NewSvgBusIcon />),
                      scale: 0.7,
                      rotation: busRotation,
                      fillColor: "yellow",
                      fillOpacity: 1,
                      strokeOpacity: 1,
                      strokeWeight: 1,
                      strokeColor: "#000000",
                    }}
                  >
                    {isHoveredOnIcon && uniId == 1 && (
                      <InfoWindowF
                        className="p-0 m-0"
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -10),
                          maxWidth: 200,
                          closeBoxURL: "",
                        }}>
                        <div
                          className="p-2 d-flex flex-column justify-content-center"
                        >


                          <p className="m-0 mb-1">
                            Speed : <b>0 Kph</b>
                          </p>
                        </div>
                      </InfoWindowF>
                    )}
                  </Marker>
                </>}





              </GoogleMap>
            ) : <></>}
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default VehicleMap