import axios from "axios";
import { BASE_URL, BASE_URL_V3 } from "../../../utils/common/common";
import {
    GET_EPR_ROUTE_DEVICE,
    GET_EPR_ROUTE_DEVICE_ERR,
    GET_EPR_ROUTE_DEVICE_SUCCESS,
    GET_EPR_ROUTE_GEOFENCES,
    GET_EPR_ROUTE_GEOFENCES_ERR,
    GET_EPR_ROUTE_GEOFENCES_SUCCESS,
    GET_EPR_TRIP_HISTORY,
    GET_EPR_TRIP_HISTORY_ERR,
    GET_EPR_TRIP_HISTORY_SUCCESS,
    GET_EPR_USER_ROUTE,
    GET_EPR_USER_ROUTE_ERR,
    GET_EPR_USER_ROUTE_SUCCESS,
} from "../../type/erpType";
import { currentTimeFor, todayDateFun } from "../../../utils/common/commonFun";

export const getErpRouteGeofences = (payload) => {
    return {
        type: GET_EPR_ROUTE_GEOFENCES,
        payload,
    };
};

export const getErpRouteGeofencesSuccess = (payload) => {
    return {
        type: GET_EPR_ROUTE_GEOFENCES_SUCCESS,
        payload,
    };
};

export const getErpRouteGeofencesErr = (payload) => {
    return {
        type: GET_EPR_ROUTE_GEOFENCES_ERR,
        payload,
    };
};

export const getErpRouteDevice = (payload) => {
    return {
        type: GET_EPR_ROUTE_DEVICE,
        payload,
    };
};

export const getErpRouteDeviceSuccess = (payload) => {
    return {
        type: GET_EPR_ROUTE_DEVICE_SUCCESS,
        payload,
    };
};

export const getErpRouteDeviceErr = (payload) => {
    return {
        type: GET_EPR_ROUTE_DEVICE_ERR,
        payload,
    };
};

export const getErpTripHistory = (payload) => {
    return {
        type: GET_EPR_TRIP_HISTORY,
        payload,
    };
};

export const getErpTripHistorySuccess = (payload) => {
    return {
        type: GET_EPR_TRIP_HISTORY_SUCCESS,
        payload,
    };
};

export const getErpTripHistoryErr = (payload) => {
    return {
        type: GET_EPR_TRIP_HISTORY_ERR,
        payload,
    };
};

export const getUserRoute = (payload) => {
    return {
        type: GET_EPR_USER_ROUTE,
        payload,
    };
};

export const getUserRouteSuccess = (payload, errModel, routeId) => {

    return {
        type: GET_EPR_USER_ROUTE_SUCCESS,
        payload,
        errModel,
        routeId
    };
};

export const getUserRouteErr = (payload) => {
    return {
        type: GET_EPR_USER_ROUTE_ERR,
        payload,
    };
};

const authToken = localStorage.getItem("erpToken")

export const getErpRouteGeofencesApi = (payload, token) => {
    return async (dispatch) => {
        dispatch(getErpRouteGeofences);

        const config = {
            headers: {
                Authorization: authToken ? authToken : token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(
                `${BASE_URL_V3}/get_route_geofences_v2/?route_id=${payload}  `,
                config
            );

            if (!response.data.status) {
                dispatch(getErpRouteGeofencesErr(response.data));
            } else {
                dispatch(getErpRouteGeofencesSuccess(response.data));
            }
        } catch (error) {
            dispatch(getErpRouteGeofencesErr());
        }
    };
};

export const getErpRouteDeviceApi = (payload, token,myEpoch1,myEpoch2) => {
    return async (dispatch) => {
        dispatch(getErpRouteDevice());

        const config = {
            headers: {
                Authorization: authToken ? authToken : token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };


        try {
            const response = await axios.get(
                `${BASE_URL}/get_route_device/?route_id=${payload}`,
                config
            );

            if (!response.data.status) {
                dispatch(getErpRouteDeviceErr(response.data));
            } else {
                const imei = response.data.data[0].imei

                dispatch(getErpTripHistoryApi(imei, token,myEpoch1,myEpoch2));
                dispatch(getErpRouteDeviceSuccess(response.data));
            }
        } catch (error) {
            dispatch(getErpRouteDeviceErr());
        }
    };
};


export const getErpTripHistoryApi = (imei, token,myEpoch1,myEpoch2) => {

    return async (dispatch) => {
        dispatch(getErpTripHistory);

        const timeObj = new Date();
        var year = timeObj.getFullYear();
        var month = timeObj.getMonth() + 1;
        var day = timeObj.getDate();
        var dateInp = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day);
        function addLeadingZero(value) {
            return value < 10 ? '0' + value : value;
        }


        const config = {
            headers: {
                Authorization: authToken ? authToken : token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        try {
            // const response = await axios.get(myEpoch1 ? `https://locate-v2.uat.orahi.com/locate/api/trip_history_v3/?imei=${imei}&date=${dateInp}&start_time=${myEpoch1}&end_time=${myEpoch2} ` : `https://locate-v2.uat.orahi.com/locate/api/trip_history_v3/?imei=${imei}&date=${dateInp}`, config);
            const response = await axios.get(myEpoch1 ? `https://locate-dashboard-api.uat.orahi.com/locate/api/trip_history_v3/?imei=${imei}&date=${dateInp}&start_time=${myEpoch1}&end_time=${myEpoch2} ` : `https://locate-dashboard-api.uat.orahi.com/locate/api/trip_history_v3/?imei=${imei}&date=${dateInp}`, config);

            if (!response.data.status) {
                dispatch(getErpTripHistoryErr(response.data));
            } else {
                dispatch(getErpTripHistorySuccess(response.data));
            }
        } catch (error) {
            dispatch(getErpTripHistoryErr());
        }
    };
};



export const getUserRouteApi = (userdata, token) => {
    return async (dispatch) => {
        dispatch(getUserRoute);
        const config = {
            headers: {
                Authorization: authToken ? authToken : token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(
                userdata ?  `${BASE_URL}/get_user_routes/?user_id=${userdata}` : `${BASE_URL}/get_user_routes` ,
                config
            );

            if (!response.data.status) {

                dispatch(getUserRouteErr(response.data));
            }
            else {

                if (response.data.data) {
                    const array = response.data.data
                    const currentDate = new Date();
                    const dayOfWeek = currentDate.getDay();

                    const monthNames = [
                        "Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"
                    ];
                    const day1 = currentDate.getDate();
                    const monthIndex = currentDate.getMonth();
                    const year = currentDate.getFullYear();
                    const formattedDate = `${monthNames[monthIndex]} ${day1}, ${year}`;



                    const indexday = dayOfWeek - 1;
                    const resultitem = array.filter((item) => {
                        const timestringInt = parseInt(currentTimeFor().replaceAll(':', ''));
                        const starttimestringInt = parseInt(item.timeslots[indexday].from_time.replaceAll(':', ''));
                        const endtimestringInt = parseInt(item.timeslots[indexday].to_time.replaceAll(':', ''));
                        if (starttimestringInt < timestringInt && timestringInt < endtimestringInt) {
                            return true;
                        }
                    })

                    if (resultitem?.length) {

                        const findDay = resultitem[0].timeslots?.find(elem => elem.day == dayOfWeek)

                        var myDate1 = new Date(`${formattedDate} ${findDay.from_time}`);
                        var myDate2 = new Date(`${formattedDate} ${findDay.to_time}`);
                        var myEpoch1 = myDate1.getTime() / 1000.0;
                        var myEpoch2 = myDate2.getTime() / 1000.0;
                        const routeId = resultitem[0].route_id;
                        localStorage.setItem('route_id',routeId)
                        dispatch(getErpRouteGeofencesApi(routeId, token));
                        dispatch(getErpRouteDeviceApi(routeId, token,myEpoch1,myEpoch2));
                        dispatch(getUserRouteSuccess(response.data, false, routeId));

                    }
                    else {
                        const errModel = true
                        dispatch(getUserRouteSuccess(response.data, errModel));
                    }
                }
            }


        } catch (error) {
            dispatch(getErpTripHistoryErr());
        }
    };
};

