const SERVER_BASE_URL = "https://locate-dashboard-api.uat.orahi.com/locate/api"

const BASE_URL = "https://locate-dashboard-api.uat.orahi.com/locate/api"

// const BASE_URL_V3 = "https://locate-v2.uat.orahi.com/locate/api"
const BASE_URL_V3 = "https://locate-dashboard-api.uat.orahi.com/locate/api"
export class ApiURLOps {
  static BUSINESS = BASE_URL + '/business/';
  static ROUTE = BASE_URL + '/route/';
  static BUSINESS_API_SECOND = BASE_URL + '/business/';
  static DEVICE = BASE_URL + '/device/';
  static DEVICELIST = BASE_URL + '/device_list/';
  static VEHICLE = BASE_URL + '/vehicle/';
  static SIM = BASE_URL + '/sim/';
  // static SIM_Allocated= BASE_URL + '/sim_buss/';
  static CARD = BASE_URL + '/card/';
  static WATCH = BASE_URL + '/watch/';
  static USER = BASE_URL + '/user/';
  static CHILD = BASE_URL + '/child/';
  static CHILD_API_SECOND = BASE_URL + '/parent_child/';
  static BUSINESSROLE = BASE_URL + '/user_business_role/';
  static ROUTELIST = BASE_URL + '/route_list/';
  static CHILDROUTELIST = BASE_URL + '/child_route/';
static DLCONTEXT = BASE_URL + '/del_imei_tagid/'


  //detach allocated
  static DETACHDEVICEVEHICLE = BASE_URL + '/detach_device/';

  //delete child id
  static DELETE_CHILD = BASE_URL + '/child_delete/'

  //device add options
  static ModelOptions = BASE_URL + '/model_list/';
  static VendorOptions = BASE_URL + '/vendor_list/';
  static DeviceTypeOptions = BASE_URL + '/device_type_list/';
  static StatusListOptions = BASE_URL + '/status_list/';
  static OperatorOptions = BASE_URL + '/operator/';

  //add business
  static BusinessTypeOptions = BASE_URL + '/buss_type/';
  static BussinessOfferOptions = BASE_URL + '/buss_off/';
  static StateOptions = BASE_URL + '/state/';
  static CountryOptions = BASE_URL + '/country/';
  static Planoptions = BASE_URL + '/buss_plan/';
  static SubscriptionType = BASE_URL + '/sub_type/';

  //add child
  static ChildSection = BASE_URL + '/child_section/';
  static ChildClass = BASE_URL + '/child_class/';
  static ParentRelation = BASE_URL + '/parent_relation/';

  //add vehicle
  static VehicleTypeOptions = BASE_URL + '/veh_type/';

  //add user bsiness role
  static UserTypeOptions = BASE_URL + '/user_type/';

  //vehiclelist
  static vehclelistoptions = BASE_URL + '/vehicle_list/';

  //attach new device
  static AttachNewDeviceApi = BASE_URL + '/device_vehicle/';

  //attach sim device
  static AttachSimDEvice = BASE_URL + '/device_sim/';

  //allocate card sim
  static AllocateCardList = BASE_URL + '/card_list/';
  static AllocateSimList = BASE_URL + '/sim_list/';

  //device get
  static DEVICE_UNALLOCATED_LIST = BASE_URL + '/device_list/';

  //card child table
  static CardToChildTable = BASE_URL + '/child_card/';

  //userroute table
  static UserRouteTable = BASE_URL + '/user_route/';

  //assign 
  static CHILD_LIST = BASE_URL + '/child_list/'
  static CHILD_ROUTE = BASE_URL + '/child_route/'
  static CHILD_CARD = BASE_URL + '/child_card/'

  // add route
  static ROUTETYPE = BASE_URL + '/route_category/';

  //geofence
  static GEOFENCE = BASE_URL + '/geofence/';
  static GEOFENCEDETACH = BASE_URL_V3 + '/get_route_geofences_v2/';

  //users table
  static USERTABLE = BASE_URL + '/user_buss_list/';

  //add timeslot
  static TIMESLOT = BASE_URL + '/timeslot/';
  //detach geo
  static GEODETACH = BASE_URL + '/geofence_route/';
  //attach route vehicle
  static ATTACHROUTE = BASE_URL + '/route_vehicle/';
  //route table
  static ROUTETIMESLOT = BASE_URL + '/route_timeslot/';
  //attachedroutes
  static ATTACHEDROUTETABLE = BASE_URL + '/route_vehicle/';

  static MERGEVEHICLES = BASE_URL + '/merge_vehicles/';
  static UNDOMERGEVEHICLES = BASE_URL + '/undo_merge_vehicles/';
  static UNDOSWAPVEHICLES = BASE_URL + '/undo_swap_vehicles/';
  static SWAPVEHICLES = BASE_URL + '/swap_vehicles/';
  static MERGEHISTORY = BASE_URL + '/vehicles_merge_history/';
  static SWAPHISTORY = BASE_URL + '/vehicles_swap_history/';
  static GEOFENCEROUTEATTACH = BASE_URL + '/geofence_route/';
}

export class ApiURL {
  // User Login
  static SEND_OTP = SERVER_BASE_URL + '/onboarding/send_otp/';
  // User Login OTP
  static VERIFY_OTP = SERVER_BASE_URL + '/onboarding/verify_otp/';
  // User Business
  static GET_USER_BUSINESS = SERVER_BASE_URL + '/user_business/'

  // Get Assets Count
  static GET_ASSETS_COUNT = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Get a Vehicle Details
  static GET_VEHICLE_DETAILS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register Vehicle
  static VEHICLE_REGISTER = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register tags to a vehicle
  static REGISTER_TAGS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Update an existing vehicle
  static EDIT_VEHICLE = SERVER_BASE_URL + '/api/v1/vehicles/${VIN}/update';

  // Get components list
  static GET_VEHICLE_COMPONENT_LIST = SERVER_BASE_URL + '/api/v1/hvac/components/list';
  // Get HVAC components count
  static GET_HVAC_COMPONENT_COUNT = SERVER_BASE_URL + '/api/v1/hvac/';
  // Get eberspacher component serial number
  static GET_COMP_SERIAL_NUMBER = SERVER_BASE_URL + '/api/v1/hvac/components/eberspacher/';
  // Get supplier components list
  static GET_SUPPLIER_COMP_LIST = SERVER_BASE_URL + '/api/v1/hvac/components/suppliers/details';
  // Get components linked with HVAC
  static GET_COMPS = SERVER_BASE_URL + '/api/v1/hvac/';
  // Add devices
  static ADD_HVAC_COMP = SERVER_BASE_URL + '/api/v1/hvac/';
  // Edit devices
  static EDIT_HVAC_COMP = SERVER_BASE_URL + '/api/v1/hvac/';
  // Delete devices
  static DELETE_HVAC_COMP = SERVER_BASE_URL + '/api/v1/hvac/';

  // Register Gateway
  static GATEWAY_REGISTER = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Gateway Details
  static GET_GATEWAY = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Register Sim
  static SIM_REGISTER = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Edit Gateway Serial Number
  static EDIT_GATEWAY = SERVER_BASE_URL + '/api/v1/vehicles/';

  // Get Roles
  static GET_ROLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Fleet
  static GET_FLEETS = SERVER_BASE_URL + '/api/v1/companies/operators/';
  // Get Depot
  static GET_DEPOT = SERVER_BASE_URL + '/api/v1/companies/operators/';

  // Vehicle Dashboard
  // Get temperature
  static GET_TEMP = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Components Status
  static GET_COMP_STATUS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Vehicle Location
  static GET_VEHICLE_LOCATION = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Last Signal Timestamp
  static GET_SIGNAL_TIMESTAMP = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Get Vehicle Tags
  static GET_VEHICLE_TAGS = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Company Tags
  static GET_COMPANY_TAGS = SERVER_BASE_URL + 'api/v1/companies/';
  // Add Vehicle Tags
  static ADD_VEHICLE_TAGS = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Vehicle Alarms
  static GET_VEHICLE_ALARMS = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Close Vehicle Alarm
  static CLOSE_VEHICLE_ALARM = SERVER_BASE_URL + '/api/v1/vehicles/';
  // Search Vin Number
  static SEARCH_VIN_NUMBER = SERVER_BASE_URL + '/api/v1/companies/';

  // Depot Dashboard
  // Get Total Vehicles
  static GET_TOTAL_VEHICLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Health Status Summary
  static GET_HEALTH_STATUS_SUMMARY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Hvac Units By Brand
  static GET_HVAC_UNITS_BY_BRAND = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Hvac Units By Type
  static GET_HVAC_UNITS_BY_TYPE = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Number Of Vehicles With Gateway
  static GET_VEHICLES_WITH_GATEWAY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Vehicle Status
  static GET_DEPOT_VEHICLE_STATUS = SERVER_BASE_URL + '/api/v1/companies/vehicle_group/company_id/';

  // Global Dashboard
  // Get Total Company Vehicles
  static GET_TOTAL_COMPANY_VEHICLES = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Health Summary
  static GET_DEPOT_HEALTH_SUMMARY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Location
  static GET_DEPOT_LOCATION = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Depot Health Status
  static GET_DEPOT_HEALTH_STATUS = SERVER_BASE_URL + '/api/v1/companies/';

  // Health Status Dashboard
  // Get Health Status Detail
  static GET_HEALTH_STATUS_DETAIL = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Get Alarm Checklist
  static GET_ALARM_CHECKLIST = SERVER_BASE_URL + '/api/v1/vehicles/';

  // Company Registeration
  // Get Registered Company
  static GET_REGISTERED_COMPANY = SERVER_BASE_URL + '/api/v1/companies/';
  // Get Company List
  static GET_COMPANY_LIST = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Add Company
  static ADD_COMPANY = SERVER_BASE_URL + '/api/v1/companies/company/register';
  // Update Company
  static UPDATE_COMPANY_DETAILS = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Company Relation
  static GET_COMPANY_RELATION = SERVER_BASE_URL + '/api/v1/companies/companies/fetch';
  static REGISTER_COMPANY_RELATION = SERVER_BASE_URL + '/api/v1/companies/companies/fetch';

  // Fleet Registration
  // Add Fleet
  static REGISTER_FLEET = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Fleet
  static GET_REGISTERED_FLEET = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Fleet List
  static GET_FLEET_LIST = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  //Update Fleet
  static UPDATE_FLEET = SERVER_BASE_URL + '/api/v1/companies/company/';

  // Depot Registration
  // Add Depot
  static REGISTER_DEPOT = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Depot
  static GET_REGISTERED_DEPOT = SERVER_BASE_URL + '/api/v1/companies/company/';
  // Get Depot List
  static GET_DEPOT_LIST = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Get Depot LIst By Company
  static GET_DEPOT_LIST_BY_COMPANY = SERVER_BASE_URL + '/api/v1/companies/company_id/';
  // Update Depot
  static UPDATE_DEPOT = SERVER_BASE_URL + '/api/v1/companies/company/';

  // User Registration
  // Add User
  static REGISTER_USER = SERVER_BASE_URL + '/api/v1/users/';
  // Get User
  static GET_REGISTERED_USER = SERVER_BASE_URL + '/api/v1/users/company/';
  // Get User List
  static GET_USER_LIST = SERVER_BASE_URL + '/api/v1/users/company/';
  // Update User
  static UPDATE_USER = SERVER_BASE_URL + '/api/v1/users/company/';

  // System Settings
  static GET_SYS_SETTINGS_COUNT = SERVER_BASE_URL + '/api/v1/companies/company_id/';

  // Get Role List
  static GET_ROLE_LIST = SERVER_BASE_URL + '/api/v1/core/role';
}
