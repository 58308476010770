import axios from "axios";
import { BASE_URL, COMM_BASE_URL } from "../../../utils/common/common";
import { GET_CHILD_REPORT_DATA, GET_CHILD_REPORT_DATA_ERR, GET_CHILD_REPORT_DATA_LOD, GET_CHILD_REPORT_DATA_LOD_ERR, GET_CHILD_REPORT_DATA_LOD_SUCCESS, GET_CHILD_REPORT_DATA_SUCCESS, GET_STOP_REPORT, GET_STOP_REPORT_ERR, GET_STOP_REPORT_SUCCESS, GET_TRANSPORT_REPORT, GET_TRANSPORT_REPORT_ERR, GET_TRANSPORT_REPORT_SUCCESS, GET_VEHICLES_REPORT, GET_VEHICLES_REPORT_ERR, GET_VEHICLES_REPORT_SUCCESS, LODING_TRANSPORT_REPORT, LODING_TRANSPORT_REPORT_ERR, LODING_TRANSPORT_REPORT_SUCCESS, REPORT_FILTER, REPORT_FILTER_ERR, REPORT_FILTER_SUCCESS, STOP_LIST, STOP_LIST_ERR, STOP_LIST_SUCCESS } from "../../type/vehicalType";
import { showNotification } from "../../../shared/components/Notification";


export const getStopReport = (payload) => {
    return {
        type: GET_STOP_REPORT,
        payload
    }
}

export const getStopReportSuccess = (payload) => {
    return {
        type: GET_STOP_REPORT_SUCCESS,
        payload
    }
}

export const getStopReportErr = (payload) => {
    return {
        type: GET_STOP_REPORT_ERR,
        payload
    }
}


export const getVecReport = (payload) => {
    return {
        type: GET_VEHICLES_REPORT,
        payload
    }
}

export const getVecReportSuccess = (payload) => {

    return {
        type: GET_VEHICLES_REPORT_SUCCESS,
        payload
    }
}

export const getVecReportErr = (payload) => {
    return {
        type: GET_VEHICLES_REPORT_ERR,
        payload
    }
}



export const getTransReport = (payload) => {
    return {
        type: GET_TRANSPORT_REPORT,
        payload
    }
}

export const getTransReportSuccess = (payload) => {
    return {
        type: GET_TRANSPORT_REPORT_SUCCESS,
        payload
    }
}

export const getTransReportErr = (payload) => {
    return {
        type: GET_TRANSPORT_REPORT_ERR,
        payload
    }
}



export const lodingTransReport = (payload) => {
    return {
        type: LODING_TRANSPORT_REPORT,
        payload
    }
}

export const lodingTransReportSuccess = (payload) => {

    return {
        type: LODING_TRANSPORT_REPORT_SUCCESS,
        payload
    }
}

export const lodingTransReportErr = (payload) => {
    return {
        type: LODING_TRANSPORT_REPORT_ERR,
        payload
    }
}


export const reportFilter = (payload) => {
    return {
        type: REPORT_FILTER,
        payload
    }
}

export const reportFilterSuccess = (payload) => {

    return {
        type: REPORT_FILTER_SUCCESS,
        payload
    }
}

export const reportFilterErr = (payload) => {
    return {
        type: REPORT_FILTER_ERR,
        payload
    }
}



export const stopList = (payload) => {
    return {
        type: STOP_LIST,
        payload
    }
}

export const stopListSuccess = (payload) => {

    return {
        type: STOP_LIST_SUCCESS,
        payload
    }
}

export const stopListErr = (payload) => {
    return {
        type: STOP_LIST_ERR,
        payload
    }
}



export const getChildStopReport = (payload) => {
    return {
        type: GET_CHILD_REPORT_DATA,
        payload
    }
}

export const getChildStopReportSuccess = (payload) => {
    return {
        type: GET_CHILD_REPORT_DATA_SUCCESS,
        payload
    }
}

export const getChildStopReportErr = (payload) => {
    return {
        type: GET_CHILD_REPORT_DATA_ERR,
        payload
    }
}



export const getChildStopReportLoding = (payload) => {
    return {
        type: GET_CHILD_REPORT_DATA_LOD,
        payload
    }
}

export const getChildStopReportLodingSuccess = (payload) => {

    return {
        type: GET_CHILD_REPORT_DATA_LOD_SUCCESS,
        payload
    }
}

export const getChildStopReportLodingErr = (payload) => {
    return {
        type: GET_CHILD_REPORT_DATA_LOD_ERR,
        payload
    }
}








export const getStopReportApi = (formattedDate, buss_id, formattedDate1) => {


    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add leading zero if month is a single digit
    const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero if day is a single digit
    const todayDate = `${year}-${month}-${day}`;

    return async (dispatch) => {

        localStorage.setItem("reportDate", formattedDate)
        dispatch(getStopReport())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        try {
            // const response = await axios.get(`${COMM_BASE_URL}/get_stops_report/?buss_id=${buss_id}&date=${formattedDate}`,


            if (formattedDate1) {
                const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_stops_report_v2/?start_date=${formattedDate}&buss_id=${buss_id}&end_date=${formattedDate1}`,
                    config
                );

                if (!response.data.status) {
                    dispatch(getStopReportErr())

                    showNotification('', 'danger', 'Error', 'Stop coverage data not found');

                }
                else {
                    showNotification('', 'success', 'Success', "Stop coverage data found");
                    dispatch(getStopReportSuccess(response.data.data))

                }
            }





            else {
                const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_stops_report_v2/?start_date=${formattedDate}&buss_id=${buss_id}&end_date=${formattedDate}`,
                    config
                );

                if (!response.data.status) {
                    dispatch(getStopReportErr())

                    showNotification('', 'danger', 'Error', 'Stop coverage data not found');

                }
                else {
                    showNotification('', 'success', 'Success', "Stop coverage data found");
                    dispatch(getStopReportSuccess(response.data.data))

                }
            }




        } catch (error) {
            dispatch(getStopReportErr())
            showNotification('', 'danger', 'Error', 'Something went wrong');


        }

    };
};



export const getVecReportApi = (formattedDate, buss_id, formattedDate1) => {
    return async (dispatch) => {

        localStorage.setItem("reportDate", formattedDate)
        dispatch(getVecReport())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {

            if (formattedDate1) {
                const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_vehicles_report_v2/?start_date=${formattedDate}&buss_id=${buss_id}&end_date=${formattedDate1}`,

                    config
                );


                if (!response.data.status) {
                    dispatch(getVecReportErr())
                    showNotification('', 'danger', 'Error', 'Over-speeding vehicles not found');

                }
                else {
                    showNotification('', 'success', 'Success', "Over-speeding vehicles Data Found");
                    dispatch(getVecReportSuccess(response.data.data))

                }

            }
            else {
                const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_vehicles_report_v2/?start_date=${formattedDate}&buss_id=${buss_id}&end_date=${formattedDate}`,

                    config
                );


                if (!response.data.status) {
                    dispatch(getVecReportErr())
                    showNotification('', 'danger', 'Error', 'Over-speeding vehicles not found');

                }
                else {
                    showNotification('', 'success', 'Success', "Over-speeding vehicles Data Found");
                    dispatch(getVecReportSuccess(response.data.data))


                }

            }

        } catch (error) {
            dispatch(getVecReportErr())
            showNotification('', 'danger', 'Error', 'Something went wrong');


        }

    };
};











// ------------------------------------------------------ TRANSPORT ------------------------------------------------------


export const getTransReportApi = (buss_id) => {
    return async (dispatch) => {


        dispatch(getTransReport())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {


            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_child_report_data/?buss_id=${buss_id}&offset=0`,

                config
            );



            if (!response.data.status) {
                dispatch(getTransReportErr())

            }
            else {

                dispatch(getTransReportSuccess(response.data.data))
                const total_enteries = response?.data?.data.total_enteries
                const offset_value = response?.data?.data?.offset_value
                if (total_enteries > offset_value) {
                    dispatch(loadingTransReportApi(buss_id, total_enteries, offset_value))

                } else {

                }


            }
        } catch (error) {
            dispatch(getTransReportErr())


        }

    };
};

// ----------loding----------------------

export const loadingTransReportApi = (buss_id, total_enteries, offset_value) => {
    return async (dispatch) => {


        dispatch(lodingTransReport())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_child_report_data/?buss_id=${buss_id}&offset=${offset_value}`,
                config
            );
            if (!response.data.status) {
                dispatch(lodingTransReportErr())

            }
            else {


                const new_offset_value = response.data.data.offset_value + offset_value
                dispatch(lodingTransReportSuccess(response.data.data))
                if (new_offset_value && new_offset_value <= total_enteries) {
                    dispatch(loadingTransReportApi(buss_id, total_enteries, new_offset_value))
                }


            }



        } catch (error) {
            dispatch(lodingTransReportErr())
            showNotification('', 'danger', 'Error', 'Something went wrong');


        }

    };
};




// --------------------------------------------------------- filter data ------------------------------------------------





export const reportFilterApi = (buss_id) => {
    return async (dispatch) => {


        dispatch(reportFilter())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_filter_data/?buss_id=${buss_id}`,
                config
            );


            if (!response.data.status) {
                dispatch(reportFilterErr())
            }
            else {
                dispatch(reportFilterSuccess(response.data.data))

            }
        } catch (error) {
            dispatch(reportFilterErr())
        }

    };
};




// export const STOP_LIST = "STOP_LIST"--------------------------------------------------







export const stopListApi = (buss_id) => {
    return async (dispatch) => {


        dispatch(stopList())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/geofence_route/?buss_id=${buss_id}`,
                config
            );


            if (!response.data.status) {
                dispatch(stopListErr())
            }
            else {
                dispatch(stopListSuccess(response.data.data))

            }
        } catch (error) {
            dispatch(stopListErr())
        }

    };
};





// ------------------------ child stop report loading ------------------------------------------------- 



export const getChildStopReportApi = (buss_id) => {
    return async (dispatch) => {


        dispatch(getChildStopReport())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {


            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_child_stop_report_data/?buss_id=${buss_id}&offset=0
            `, config
            );



            if (!response.data.status) {
                dispatch(getChildStopReportErr())

            }
            else {

          

                const offset_value = response?.data?.data?.offset_value
                const data = response?.data?.data?.child_report_list
                const total_enteries = response?.data?.data?.total_enteries


                dispatch(getChildStopReportSuccess(data))



                if (total_enteries > offset_value) {
                    dispatch(getChildStopReportLodingApi(buss_id, total_enteries, offset_value))

                } else {
                }


            }
        } catch (error) {
            dispatch(getChildStopReportErr())


        }

    };
};









export const getChildStopReportLodingApi = (buss_id, total_enteries, offset_value) => {
    return async (dispatch) => {


        dispatch(getChildStopReportLoding())
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_child_stop_report_data/?buss_id=${buss_id}&offset=500`,
                config
            );
            if (!response.data.status) {
                dispatch(getChildStopReportLodingErr())

            }
            else {


                const new_offset_value = response.data.data.offset_value + offset_value
                dispatch(getChildStopReportLodingSuccess(response.data.data))
                if (new_offset_value && new_offset_value <= total_enteries) {
                    dispatch(getChildStopReportLodingApi(buss_id, total_enteries, new_offset_value))
                }


            }



        } catch (error) {
            dispatch(lodingTransReportErr())
            showNotification('', 'danger', 'Error', 'Something went wrong');


        }

    };
};


