
import axios from 'axios'
import { GET_ALL_IMEI, GET_ALL_IMEI_ERR, GET_ALL_IMEI_SUCCESS, GET_LAST_IMEI_INFO, GET_LAST_IMEI_INFO_ERR, GET_LAST_IMEI_INFO_SUCCESS, GET_VEHICLE_DETAIL, GET_VEHICLE_DETAIL_ERR, GET_VEHICLE_DETAIL_SUCCESS, GET_VEHICLE_ROUTE, GET_VEHICLE_ROUTE_ERR, GET_VEHICLE_ROUTE_SUCCESS, RESET_LAST_IMEI_INFO } from '../../type/vehicalType'
import { BASE_URL, BASE_URL_STG, COMM_BASE_URL } from '../../../utils/common/common'





export const getVehicleRoute = (payload) => {
    return {
        type: GET_VEHICLE_ROUTE,
        payload
    }
}

export const getVehicleRouteSuccess = (payload) => {

    return {
        type: GET_VEHICLE_ROUTE_SUCCESS,
        payload
    }
}

export const getVehicleRouteErr = (payload) => {
    return {
        type: GET_VEHICLE_ROUTE_ERR,
        payload
    }
}




export const getVehicleDetail = (payload) => {
    return {
        type: GET_VEHICLE_DETAIL,
        payload
    }
}

export const getVehicleDetailSuccess = (payload) => {

    return {
        type: GET_VEHICLE_DETAIL_SUCCESS,
        payload
    }
}

export const getVehicleDetailErr = (payload) => {
    return {
        type: GET_VEHICLE_DETAIL_ERR,
        payload
    }
}





export const getAllImei = (payload) => {
    return {
        type: GET_ALL_IMEI,
        payload
    }
}

export const getAllImeiSuccess = (payload) => {

    return {
        type: GET_ALL_IMEI_SUCCESS,
        payload
    }
}

export const getAllImeiErr = (payload) => {
    return {
        type: GET_ALL_IMEI_ERR,
        payload
    }
}



export const getLastImeiData = (payload) => {
    return {
        type: GET_LAST_IMEI_INFO,
        payload
    }
}

export const getLastImeiDataSuccess = (payload) => {

    return {
        type: GET_LAST_IMEI_INFO_SUCCESS,
        payload
    }
}

export const getLastImeiDataErr = (payload) => {
    return {
        type: GET_LAST_IMEI_INFO_ERR,
        payload
    }
}

export const resetLastImei = () => {

    return {
        type: RESET_LAST_IMEI_INFO
    }
}





export const getVehicleRouteApi = (buss_id) => {

    return async (dispatch) => {
        dispatch(getVehicleRoute);


        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/x-www-form-urlencoded",
            },
        };

        try {
            const response =buss_id ?  await axios.get(`${BASE_URL}/route/?buss_id=${buss_id}`, config) : await axios.get(`${BASE_URL}/route`, config) 

            if (!response.data.status) {
                dispatch(getVehicleRouteErr);

            } else {

                dispatch(getVehicleRouteSuccess(response.data.data));

            }
        } catch (error) {

            dispatch(getVehicleRouteErr);

        }
    };
};


export const getVehicleDetailAPI = (payload) => {

    return async (dispatch) => {
        dispatch(getVehicleDetail);


        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/x-www-form-urlencoded",
            },
        };

        try {
            const response = await axios.get(`${BASE_URL}/route/`, config);

            if (!response.data.status) {
                dispatch(getVehicleDetailErr);

            } else {

                dispatch(getVehicleDetailSuccess);

            }
        } catch (error) {

            dispatch(getVehicleDetailErr);

        }
    };


}


export const getAllImeiApi = (payload) => {

    return async (dispatch) => {
        dispatch(getAllImei);

        const buss_id = 108


        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/x-www-form-urlencoded",
            },
        };

        try {
            // const response = await axios.get(`${BASE_URL}/get_all_imei_route_info/?buss_id=${payload}`,  config);
            const response = await axios.get(`${COMM_BASE_URL}/get_all_imei_route_info/?buss_id=${payload}`, config);



            if (!response.data.status) {
                dispatch(getAllImeiErr(response.data));

            } else {

                dispatch(getAllImeiSuccess(response.data));

            }
        } catch (error) {

            dispatch(getAllImeiErr);

        }
    };


}



export const getLastImeiDataApi = (payload) => {

    return async (dispatch) => {
        dispatch(getLastImeiData());

        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/x-www-form-urlencoded",
            },
        };

        try {
            // const response = await axios.get(`${BASE_URL}/get_all_imei_route_info/?buss_id=${payload}`,  config);
            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_last_bus_location/?buss_id=${payload}`, config);

            if (!response.data.status) {
                dispatch(getLastImeiDataErr(response.data));

            } else {

                dispatch(getLastImeiDataSuccess(response.data));

            }
        } catch (error) {

            dispatch(getLastImeiDataErr);

        }
    };


}