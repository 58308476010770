import axios from "axios"
import { GET_GEOFENCE_BY_ID, GET_GEOFENCE__BY_ID_ERR, GET_GEOFENCE__BY_ID_SUCCESS } from "../../type/busTripType"
import { BASE_URL, BASE_URL_V3 } from "../../../utils/common/common"

export const getGeofenceById = (payload) => {
    return {
        type: GET_GEOFENCE_BY_ID,
        payload
    }
}

export const getGeofenceByIdSuccess = (payload) => {

    return {
        type: GET_GEOFENCE__BY_ID_SUCCESS,
        payload
    }
}

export const getGeofenceByIdErr = (payload) => {
    return {
        type: GET_GEOFENCE__BY_ID_ERR,
        payload
    }
}



export const geofenceByIDActions = async (payload, dispatch) => {

    const config = {
        headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': "application/x-www-form-urlencoded",
        },
    };

    try {
        const response = await axios.get(`${BASE_URL_V3}/get_route_geofences_v2/?route_id=${payload}`, config);

        if (!response.data.status) {
            dispatch(getGeofenceByIdErr);

        } else {
            dispatch(getGeofenceByIdSuccess(response.data.data));
        }
    } catch (error) {
        dispatch(getGeofenceByIdErr);
    }
};
