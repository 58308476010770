export const GET_ALL_VEHICLE = "GET_ALL_VEHICLE"
export const GET_ALL_VEHICLE_SUCCESS = "GET_ALL_VEHICLE_SUCCESS"
export const GET_ALL_VEHICLE_ERR = "GET_ALL_VEHICLE_ERR"


export const GET_VEHICLE_ROUTE = "GET_VEHICLE_ROUTE"
export const GET_VEHICLE_ROUTE_SUCCESS = "GET_VEHICLE_ROUTE_SUCCESS"
export const GET_VEHICLE_ROUTE_ERR = "GET_VEHICLE_ROUTE_ERR"


export const GET_VEHICLE_DETAIL = "GET_VEHICLE_DETAIL"
export const GET_VEHICLE_DETAIL_SUCCESS = "GET_VEHICLE_DETAIL_SUCCESS"
export const GET_VEHICLE_DETAIL_ERR = "GET_VEHICLE_DETAIL_ERR"


export const GET_ALL_IMEI = "GET_ALL_IMEI"
export const GET_ALL_IMEI_SUCCESS = "GET_ALL_IMEI_SUCCESS"
export const GET_ALL_IMEI_ERR = "GET_ALL_IMEI_ERR"


export const GET_LAST_IMEI_INFO = "GET_LAST_IMEI_INFO"
export const GET_LAST_IMEI_INFO_SUCCESS = "GET_LAST_IMEI_INFO_SUCCESS"
export const GET_LAST_IMEI_INFO_ERR = "GET_LAST_IMEI_INFO_ERR"

export const RESET_LAST_IMEI_INFO="RESET_LAST_IMEI_INFO"


export const GET_REPORT_OPTION = "GET_REPORT_OPTION"
export const GET_REPORT_OPTION_SUCCESS = "GET_REPORT_OPTION_SUCCESS"
export const GET_REPORT_OPTION_ERR = "GET_REPORT_OPTION_ERR"

export const GET_ROUTE_OPTION = "GET_ROUTE_OPTION"
export const GET_ROUTE_OPTION_SUCCESS = "GET_ROUTE_OPTION_SUCCESS"
export const GET_ROUTE_OPTION_ERR = "GET_ROUTE_OPTION_ERR"

export const GET_STOP_REPORT = "GET_STOP_REPORT"
export const GET_STOP_REPORT_SUCCESS = "GET_STOP_REPORT_SUCCESS"
export const GET_STOP_REPORT_ERR = "GET_STOP_REPORT_ERR"


export const GET_VEHICLES_REPORT = "GET_VEHICLES_REPORT"
export const GET_VEHICLES_REPORT_SUCCESS = "GET_VEHICLES_REPORT_SUCCESS"
export const GET_VEHICLES_REPORT_ERR = "GET_VEHICLES_REPORT_ERR"


export const GET_TRANSPORT_REPORT = "GET_TRANSPORT_REPORT"
export const GET_TRANSPORT_REPORT_SUCCESS = "GET_TRANSPORT_REPORT_SUCCESS"
export const GET_TRANSPORT_REPORT_ERR = "GET_TRANSPORT_REPORT_ERR"


export const LODING_TRANSPORT_REPORT = "LODING_TRANSPORT_REPORT"
export const LODING_TRANSPORT_REPORT_SUCCESS = "LODING_TRANSPORT_REPORT_SUCCESS"
export const LODING_TRANSPORT_REPORT_ERR = "LODING_TRANSPORT_REPORT_ERR"


export const REPORT_FILTER = "REPORT_FILTER"
export const REPORT_FILTER_SUCCESS = "REPORT_FILTER_SUCCESS"
export const REPORT_FILTER_ERR = "REPORT_FILTER_ERR"


export const STOP_LIST = "STOP_LIST"
export const STOP_LIST_SUCCESS = "STOP_LIST_SUCCESS"
export const STOP_LIST_ERR = "STOP_LIST_ERR"






export const GET_CHILD_REPORT_DATA = "GET_CHILD_REPORT_DATA"
export const GET_CHILD_REPORT_DATA_SUCCESS = "GET_CHILD_REPORT_DATA_SUCCESS"
export const GET_CHILD_REPORT_DATA_ERR = "GET_CHILD_REPORT_DATA_ERR"


export const GET_CHILD_REPORT_DATA_LOD = "GET_CHILD_REPORT_DATA_LOD"
export const GET_CHILD_REPORT_DATA_LOD_SUCCESS = "GET_CHILD_REPORT_DATA_LOD_SUCCESS"
export const GET_CHILD_REPORT_DATA_LOD_ERR = "GET_CHILD_REPORT_DATA_LOD_ERR"

