import {
  GET_ALL_IMEI,
  GET_ALL_IMEI_ERR,
  GET_ALL_IMEI_SUCCESS,
  GET_LAST_IMEI_INFO,
  GET_LAST_IMEI_INFO_ERR,
  GET_LAST_IMEI_INFO_SUCCESS,
  GET_VEHICLE_DETAIL,
  GET_VEHICLE_DETAIL_ERR,
  GET_VEHICLE_DETAIL_SUCCESS,
  GET_VEHICLE_ROUTE,
  GET_VEHICLE_ROUTE_ERR,
  GET_VEHICLE_ROUTE_SUCCESS,
  RESET_LAST_IMEI_INFO
} from "../../type/vehicalType";

const initialState = {
  routeData: [],
  vehicleDetail: [],
  imeiData: [],
  getLastImeiData: [],
  business_plan: "",
  isLoding: false,
};

const getAllVehicleData = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLE_ROUTE:
      return { ...state };

    case GET_VEHICLE_ROUTE_SUCCESS:
      return {
        ...state,
        routeData: action.payload,
        business_plan: action?.payload[0]?.business_plan,
      };

    case GET_VEHICLE_ROUTE_ERR:
      return { ...state };

    case GET_VEHICLE_DETAIL:
      return { ...state };

    case GET_VEHICLE_DETAIL_SUCCESS:
      return { ...state };

    case GET_VEHICLE_DETAIL_ERR:
      return { ...state };

    case GET_ALL_IMEI:
      return { ...state,imeiData:[] };

    case GET_ALL_IMEI_SUCCESS:
      return { ...state, imeiData: action.payload.data };

    case GET_ALL_IMEI_ERR:
      return { ...state,imeiData:[] };

    case GET_LAST_IMEI_INFO:
      return { ...state, isLoding: true ,getLastImeiData:[]};

    case GET_LAST_IMEI_INFO_SUCCESS:
      return {
        ...state,
        getLastImeiData: action.payload.data,
        isLoding: false,
      };

    case GET_LAST_IMEI_INFO_ERR:
      return { ...state, isLoding: false ,getLastImeiData:[]};
    case RESET_LAST_IMEI_INFO:
      return initialState;

    default:
      return { ...state };
  }
};

export default getAllVehicleData;
