import { GET_CHILD_REPORT_DATA, GET_CHILD_REPORT_DATA_ERR, GET_CHILD_REPORT_DATA_LOD, GET_CHILD_REPORT_DATA_LOD_ERR, GET_CHILD_REPORT_DATA_LOD_SUCCESS, GET_CHILD_REPORT_DATA_SUCCESS, GET_REPORT_OPTION, GET_REPORT_OPTION_ERR, GET_REPORT_OPTION_SUCCESS, GET_STOP_REPORT, GET_STOP_REPORT_ERR, GET_STOP_REPORT_SUCCESS, GET_TRANSPORT_REPORT, GET_TRANSPORT_REPORT_ERR, GET_TRANSPORT_REPORT_SUCCESS, GET_VEHICLES_REPORT, GET_VEHICLES_REPORT_ERR, GET_VEHICLES_REPORT_SUCCESS, LODING_TRANSPORT_REPORT, LODING_TRANSPORT_REPORT_ERR, LODING_TRANSPORT_REPORT_SUCCESS, REPORT_FILTER, REPORT_FILTER_ERR, REPORT_FILTER_SUCCESS, STOP_LIST, STOP_LIST_ERR, STOP_LIST_SUCCESS } from "../../type/vehicalType";

const initialState = {
    reportOption: [],
    stopReport: [],
    stopLoder: false,
    vecReport: [],
    vecLoder: false,
    transLoder: false,
    transData: [],
    total_enteries: 0,
    lodingLoder: false,
    filterData: '',
    filterLoder: false,
    stopListData: [],
    stopListLoder: false,
    childReportData:[],
    childReportLoder:false,
    childReportLoderLoading:false

};




const reportData = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT_OPTION:
            return { ...state }
        case GET_REPORT_OPTION_SUCCESS:
            return { ...state, reportOption: action.payload }
        case GET_REPORT_OPTION_ERR:
            return { ...state }

        case GET_STOP_REPORT:
            return { ...state, stopLoder: true }
        case GET_STOP_REPORT_SUCCESS:
            return { ...state, stopLoder: false, stopReport: action.payload }
        case GET_STOP_REPORT_ERR:
            return { ...state, stopLoder: false, stopReport: [] }


        case GET_VEHICLES_REPORT:
            return { ...state, vecLoder: true }
        case GET_VEHICLES_REPORT_SUCCESS:
            return { ...state, vecLoder: false, vecReport: action.payload }
        case GET_VEHICLES_REPORT_ERR:
            return { ...state, vecLoder: false }


        case GET_TRANSPORT_REPORT:
            return { ...state, transLoder: true }
        case GET_TRANSPORT_REPORT_SUCCESS:
            return {
                ...state,
                transLoder: false,
                transData: action.payload.child_report_list,
                total_enteries: action.payload.total_enteries
            }
        case GET_TRANSPORT_REPORT_ERR:
            return { ...state, transLoder: false }


        case LODING_TRANSPORT_REPORT:
            return { ...state, lodingLoder: true }
        case LODING_TRANSPORT_REPORT_SUCCESS:
            return { ...state, transData: [...state.transData, ...action.payload.child_report_list], lodingLoder: false }
        case LODING_TRANSPORT_REPORT_ERR:
            return { ...state }


        case REPORT_FILTER:
            return { ...state, filterLoder: true }
        case REPORT_FILTER_SUCCESS:
            return { ...state, filterData: action.payload, filterLoder: false }
        case REPORT_FILTER_ERR:
            return { ...state, filterLoder: false }



        case STOP_LIST:
            return { ...state, stopListLoder: true }
        case STOP_LIST_SUCCESS:
            return {
                ...state,
                stopListData: action.payload,
                stopListLoder: false
            }
        case STOP_LIST_ERR:
            return { ...state, stopListLoder: false }



        case GET_CHILD_REPORT_DATA:
            return { ...state, childReportLoder: true }
        case GET_CHILD_REPORT_DATA_SUCCESS:
            return {
                ...state,
                childReportData: action.payload,
                childReportLoder: false
            }
        case GET_CHILD_REPORT_DATA_ERR:
            return { ...state, childReportLoder: false }




        case GET_CHILD_REPORT_DATA_LOD:
            return { ...state, childReportLoderLoading: true }
        case GET_CHILD_REPORT_DATA_LOD_SUCCESS:
           
            return {
                ...state,
                childReportData: [...state.childReportData, ...action.payload.child_report_list ],
                childReportLoderLoading: false
            }
        case GET_CHILD_REPORT_DATA_LOD_ERR:
            return { ...state, childReportLoderLoading: false }



        default:
            return { ...state }
    }
};

export default reportData;
