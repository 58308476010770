import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import renderSelectField from '@/shared/components/form/Select';
import { ApiURLOps } from "../../../../config/GlobalUrl";
import { FieldName, StyledInput } from "../Common/CommonCss";
import { showNotification } from "../../../../shared/components/Notification";
import { useDispatch, useSelector } from "react-redux";
import statusListOptions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/statusListOptions";
import allocateddeviceaction from "../../../../redux/actions/OpsDashboardActions/allocatedreducers.js/allocateddeviceaction";
import modelOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/modelOptionsmodelOptionsAction";
import vendorOptionsAction from "../../../../redux/actions/OpsDashboardActions/OptionsGet/vendorOptionsAction";
import deviceTypeOPtions from "../../../../redux/actions/OpsDashboardActions/OptionsGet/deviceTypeOPtions";

const EditDeviceForm = ({ editdata, setShowModal, setloadingapi, setApiResponse }) => {
    const dispatch = useDispatch();
    const [opsComments, setopscomments] = useState('');
    const [name, setname] = useState('');
    const [status, setstatus] = useState('');
    const [imei, setimei] = useState('');

    const [vendor, setvendor] = useState('');
    const [model, setmodel] = useState('');
    const [type, settype] = useState('');

    const [modelData, setModelData] = useState([]);
    const [statusData, setstatusData] = useState([]);
    const [vendorData, setvendorData] = useState([]);
    const [dtypeData, setdtypeData] = useState([]);

    const onSubmit = () => {
        deviceUpdate();
        reset();
        setShowModal(false);
    }

    const reset = () => {
        setopscomments('');
        setname('');
        setstatus('');
        setopscomments('');
    }

    const getmodelreducer = useSelector(
        (state) => state.modelOptionsReducer.info
    );
    const getmodelreducerloading = useSelector(
        (state) => state.modelOptionsReducer.loading
    );
    const getvendorreducer = useSelector(
        (state) => state.vendorOptionsReducer.info
    );
    const getvendorreducerloading = useSelector(
        (state) => state.vendorOptionsReducer.loading
    );
    const getdtypereducer = useSelector(
        (state) => state.deviceTypeOptionsReducer.info
    );
    const getdtypereducerloading = useSelector(
        (state) => state.deviceTypeOptionsReducer.loading
    );
    const getstatusreducer = useSelector(
        (state) => state.statusListOptionsReducer.info
    );
    const getstatusreducerloading = useSelector(
        (state) => state.statusListOptionsReducer.loading
    );
    const statusOptions = statusData.filter((item) => item.category === 'device').map((item) => { return { id: item.id, value: item.status, label: item.status } });
    const initstatus = statusOptions.length > 0 ? statusOptions.filter((item) => item.label === editdata.status) : '';

    const dtypeOptions = dtypeData.map((item) => { return { id: item.id, value: item.type, label: item.type } });
    const inittype = dtypeOptions.length > 0 ? dtypeOptions.filter((item) => item.id === editdata.typeid) : '';

    const vendorOptions = vendorData.filter((item) => item.category === 'device').map((item) => { return { id: item.id, value: item.name, label: item.name } });
    const initvendor = vendorOptions.length > 0 ? vendorOptions.filter((item) => item.id === editdata.vendorid) : '';

    const modelOptions = modelData.filter((item) => item.category === 'device').map((item) => { return { id: item.id, value: item.model, label: item.model } });
    const initmodel = modelOptions.length > 0 ? modelOptions.filter((item) => item.id === editdata.modelid) : '';


    useEffect(() => {
        if (getmodelreducer.status) {
            setModelData(getmodelreducer.data);
        }
        if (getvendorreducer.status) {
            setvendorData(getvendorreducer.data);
        }
        if (getstatusreducer.status) {
            setstatusData(getstatusreducer.data);
        }
        if (getdtypereducer.status) {
            setdtypeData(getdtypereducer.data);
        }
    }, [getdtypereducer, getstatusreducer, getvendorreducer, getmodelreducer])

    const deviceGPSadd = async () => {
        try {
            setloadingapi(true);
            const response = await axios(ApiURLOps.DEVICE_UNALLOCATED_LIST, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            setloadingapi(false);
            if (response.data.data) {
                setApiResponse(response.data.data);
            }
            return response.data.data;
        }
        catch (error) {
            setloadingapi(false);
        }
        return true;
    }

    const deviceUpdate = async () => {
        const body = !editdata.buss_id ? {
            ops_comments: opsComments ? opsComments : editdata.opsc,
            status: status ? status : initstatus.statusid,
            name: name ? name : editdata.name,
            imei: imei ? imei : editdata.imei,
            model: model ? model : editdata.modelid,
            vendor: vendor ? vendor : editdata.vendorid,
            type_dev: type ? type : editdata.typeid,
            device_id: parseInt(editdata.id)
        } : {
            ops_comments: opsComments ? opsComments : editdata.opsc,
            status: status ? status : initstatus.statusid,
            name: name ? name : editdata.name,
            imei: imei ? imei : editdata.imei,
            model: model ? model : editdata.modelid,
            vendor: vendor ? vendor : editdata.vendorid,
            type_dev: type ? type : editdata.typeid,
            device_id: parseInt(editdata.id),
            buss_id: editdata.buss_id
        }

        try {
            const response = await axios.patch(ApiURLOps.DEVICE, body, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': "application/json",
                },
            });
            if (response.data.status) {
                showNotification('', 'success', 'Success', 'Data successfully Updated');
                if (!editdata.buss_id) {
                    deviceGPSadd();
                }
                if (editdata.buss_id) {
                    dispatch(allocateddeviceaction(editdata.buss_id));
                }
            }
            if (!response.data.status) {
                showNotification('', 'danger', 'Error', response.data.message);
            }
            return response.data.data;

        }
        catch (error) {
            showNotification('', 'danger', 'Error', 'Something went wrong');

        }
    }

    useEffect(() => {
        dispatch(modelOptionsAction());
        dispatch(vendorOptionsAction());
        dispatch(deviceTypeOPtions());
        dispatch(statusListOptions());
    }, [])

    return (
        <Form onSubmit={onSubmit} >
            {({ handleSubmit, submitting, form, values }) => (
                <div>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginTop: '-40px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Model:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Vendor:</FieldName>
                        </Col>
                        <Col >
                            <FieldName>Type:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '2vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <Field
                                name="model"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Model"
                                options={modelOptions}
                                parse={(e) => {
                                    setmodel(e.id);
                                    return e;
                                }}
                                loading={getmodelreducerloading}
                                initialValue={initmodel}
                                height={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="vendor"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Vendor"
                                options={vendorOptions}
                                parse={(e) => {
                                    setvendor(e.id);
                                    return e;
                                }}
                                loading={getvendorreducerloading}
                                initialValue={initvendor}
                                height={true}
                            />

                        </Col>
                        <Col>
                            <StyledInput
                                name="type"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Type"
                                options={dtypeOptions}
                                parse={(e) => {
                                    settype(e.id);
                                    return e;
                                }}
                                loading={getdtypereducerloading}
                                initialValue={inittype}
                                height={true}
                            />

                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>Name:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>IMEI:</FieldName>
                        </Col>
                        <Col>
                            <FieldName>Status:</FieldName>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '2vh' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <StyledInput
                                name="imei"
                                component="input"
                                placeholder="IMEI"
                                parse={(e) => {
                                    setimei(e);
                                    return e;
                                }}
                                initialValue={editdata.imei}

                            />
                        </Col>
                        <Col>
                            <StyledInput
                                name="name"
                                component="input"
                                placeholder="Enter Device Name"
                                parse={(e) => {
                                    setname(e);
                                    return e;
                                }}
                                initialValue={editdata.name}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="status"
                                component={renderSelectField}
                                // isMulti
                                placeholder="Select Status"
                                options={statusOptions}
                                parse={(e) => {
                                    setstatus(e.id);
                                    return e;
                                }}
                                loading={getstatusreducerloading}
                                initialValue={initstatus}
                                height={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <Col style={{ paddingLeft: '0px !important' }}>
                            <FieldName>OPS Comments:</FieldName>
                        </Col>
                        <Col />
                        <Col />
                    </Row>
                    <Row style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '7.4vh' }}>
                        <Col style={{ paddingRight: '0px !important' }}>
                            <StyledInput
                                name="opscomments"
                                component="input"
                                placeholder="Enter Comment"
                                parse={(e) => {
                                    setopscomments(e);
                                    return e;
                                }}
                                initialValue={editdata.opsc}
                            />
                        </Col>
                        <Col />
                        <Col />
                    </Row>
                    <SubmitDiv>
                        <NextButton onClick={() => onSubmit(values)}>Submit</NextButton>
                    </SubmitDiv>
                </div>
            )
            }
        </Form >
    )

}

const NextButton = styled.button`
    color: white;
    background: #0757C2; ;
    border: none;
    width: 50%;
    height: 32px;
    padding-right:0px;
`
const SubmitDiv = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`

export default EditDeviceForm;