import axios from "axios"
import { GET_CHILD_HISTORY, GET_CHILD_HISTORY_ERR, GET_CHILD_HISTORY_SUCCESS, GET_ROUTE_CHILD, GET_ROUTE_CHILD_ERR, GET_ROUTE_CHILD_SUCCESS } from "../../type/attendanceType"
import { currentTimeFor, todayDateFun } from "../../../utils/common/commonFun"

export const getRouteChild = (payload) => {
    return {
        type: GET_ROUTE_CHILD,
        payload
    }
}

export const getRouteChildSuccess = (payload) => {


    return {
        type: GET_ROUTE_CHILD_SUCCESS,
        payload
    }
}

export const getRouteChildErr = (payload) => {
    return {
        type: GET_ROUTE_CHILD_ERR,
        payload
    }
}


export const getChildHistory = (payload) => {
    return {
        type: GET_CHILD_HISTORY,
        payload
    }
}

export const getChildHistorySuccess = (payload) => {


    return {
        type: GET_CHILD_HISTORY_SUCCESS,
        payload
    }
}

export const getChildHistoryErr = (payload) => {
    return {
        type: GET_CHILD_HISTORY_ERR,
        payload
    }
}



export const getRouteChildApi = (route, bussId, currdate) => {

    return async (dispatch) => {
        dispatch(getRouteChild());
        const token = localStorage.getItem('token')

        const config = {
            headers: {
                'Authorization': token,
                'Content-Type': "application/json",
            },
        };

        const apiDate = currdate ? currdate : ""

        try {

            const response =  await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_route_child_report_v1?route_id=${route}&buss_id=${bussId}&date=${currdate}` , config);
            if (!response.data.status) {
                dispatch(getRouteChildErr(response.data));

            } else {

                dispatch(getRouteChildSuccess(response.data));

            }
        } catch (error) {

            dispatch(getRouteChildErr());

        }
    };


}

export const getChildHistoryApi = (routeId,currDate) => {

    return async (dispatch) => {
        dispatch(getChildHistory());
        const config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': "application/json",
            },
        };
        try {
            
            const response = await axios.get(`https://locate-dashboard-api.uat.orahi.com/locate/api/get_child_history_v2?route_id=${routeId}&child_id=19604&date=${currDate}`, config);

            if (!response.data.status) {
                dispatch(getChildHistoryErr(response.data));

            } else {

                dispatch(getChildHistorySuccess(response.data));

            }
        } catch (error) {

            dispatch(getChildHistoryErr());

        }
    };


}